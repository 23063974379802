import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"

export const query = graphql`
  query($slug: String) {
    contentfulCategory(slug: { eq: $slug }) {
      title
    }
  }
`

const FeaturePage = props => {
    const { title } = props.data.contentfulCategory;
    return (
        <Layout>
            <Seo title={title} />
            <h1>{title}</h1>
        </Layout>
    )
}

export default FeaturePage
